<template>
  <div class="synchronize-shop">
    <template v-if="loading">
      {{$t('common_synchronizing.localization_value.value')}}...
    </template>

    <div class="error-synchronize mt-3" v-if="error">


      <div class="custom-col custom-col--50 custom-col--md-100">
        <b class="mb-3 d-flex color-red">{{error}}</b>
      </div>

      <div class="custom-col custom-col--25 custom-col--md-100">


        <DefaultInput
                :label="$t('common_shopName.localization_value.value')"
                :type="'text'"
                v-model="shopName"
                class="mb-3"
        />

        <MainButton
                :value="$t('common_submit.localization_value.value')"
                class="wfc"
                v-if="this.buttonType === 'createAndSynchronize'"
                @click.native="checkSynchronize('createAndSynchronize')"
        />

      </div>


      <!--<MainButton-->
              <!--:value="'Synchronize'"-->
              <!--class="wfc"-->
              <!--v-if="synchronizeButton"-->
              <!--@click.native="synchronize"-->
      <!--/>-->

    </div>
  </div>
</template>

<script>
  import MainButton from "../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "SynchronizeShop",

    components: {DefaultInput, MainButton},

    data() {
      return {
        loading: true,
        error: false,
        buttonType: false,
        shopName: false,
        synchronizeButton: false,
        redirectUrl: false,
      }
    },

    mounted() {

      this.checkSynchronize()

    },

    methods: {

      checkSynchronize(buttonType = false) {
        let locationUrl = new URL(location.href)
        let params = {
          shopType: locationUrl.searchParams.get('shopType'),
          shopName: locationUrl.searchParams.get('shopName'),
          shopLink: locationUrl.searchParams.get('shopLink'),
          backUrl: locationUrl.searchParams.get('backUrl'),
        }

        if(buttonType){
          params.buttonType = buttonType
          params.shopName = this.shopName
        }

        this.loading = true

        if (
          params.shopType && params.shopName &&
          params.shopLink && params.backUrl
        ) {
          this.$store.dispatch('synchronizeShop', params).then(response => {
            this.loading = false

            if (response.data.errors[0] !== null) {
              this.error = response.data.errors[0]

              this.buttonType = response.data.buttonType
              this.shopName = response.data.fromStore.shopName
            }

            if(response.data.redirectUrl !== null) {
              this.redirectUrl = response.data.redirectUrl
              window.location.href = this.redirectUrl
              // this.synchronizeButton = true
            }
          }).catch(error => this.createErrorLog(error))
        }
      },

      synchronize() {
        location.href = this.redirectUrl
      },

    }
  }
</script>

<style scoped>

</style>
